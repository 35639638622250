import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import {
  isWixCodeSEOEnabled,
  setInstanceGetter,
} from '@wix/wix-vod-shared/common';
import { restoreAction, createEventPublisher } from './worker/lib';
import {
  getApiBaseUrl,
  getExperimentsFromPetri,
  getMeshOverrides,
  getV3ToV2MappingBaseUrl,
  patchControllerConfig,
} from './worker/helpers';
import { getHydratedSource } from './worker/helpers/get-hydrated-source';
import { getCurrentSiteUser } from './worker/helpers/current-site-user';
import EVENTS from './constants/events';
import { initPublicServices } from './api/public';
import { createWorkerHandlerForOOI } from './worker/createHandlerForOOI';
import { createStore, InitialData } from './worker/helpers/create-store';
import { subscribeToEvents } from './worker/helpers/subscribe-to-events';
import { getStoreBuilderForOOI } from './worker/helpers/get-store-builder-for-ooi';
import { getSeoData, GetSeoDataParams } from './worker/helpers/getSeoData';
import { createHandlers } from './worker/controller-handlers';
import { initPrivateServices } from './api/private';
import { getCurrentChannelId, getChannelById } from './selectors/channels';
import { Channel } from './redux/types';
import { getVideoEntities } from './selectors/videos';

export const createController =
  (
    createWorkerHandler: Function,
    getStoreBuilder: typeof getStoreBuilderForOOI,
    isModal: boolean,
  ): CreateControllerFn =>
  async (context: ControllerParams) => {
    const {
      controllerConfig: originalControllerConfig,
      flowAPI: {
        environment: { isSSR, isMobile, isViewer },
        translations,
        reportError,
        httpClient,
      },
      flowAPI,
    } = context;
    const {
      wixCodeApi,
      platformAPIs,
      setProps,
      appParams: { instance, appDefinitionId },
    } = originalControllerConfig;
    const getAppToken = () =>
      wixCodeApi.site.getAppToken?.(appDefinitionId) ?? instance;
    setInstanceGetter(getAppToken);
    const v3ToV2MappingBaseUrl = getV3ToV2MappingBaseUrl(wixCodeApi, {
      isModal,
    });
    const { publishEvent, getEvents, consumeEvents } = createEventPublisher({
      setProps: originalControllerConfig.setProps,
    });
    const controllerConfig = patchControllerConfig(originalControllerConfig, {
      getEvents,
    });
    const experiments = await getExperimentsFromPetri(
      controllerConfig,
      isViewer,
    );
    const debug = process.env.NODE_ENV === 'development';

    let pendingCurrentSiteUser: any;

    const handleLogin = async (event: any) => {
      const currentSiteUser = await getCurrentSiteUser(event);

      platformAPIs.pubSub.publish(
        EVENTS.SESSION.LOGIN_USER,
        currentSiteUser,
        false,
      );

      pendingCurrentSiteUser = currentSiteUser;
    };

    wixCodeApi.user.onLogin(handleLogin);

    initPrivateServices(httpClient, v3ToV2MappingBaseUrl);
    initPublicServices(httpClient, instance, v3ToV2MappingBaseUrl);

    const hydratedSource = await getHydratedSource(controllerConfig, flowAPI, {
      experiments,
      isModal,

      // a ? sign is for modal, in modal it's undefined. Not sure why, but since it's not used for modal anyway,
      // I'll leave it like this for now
      translations: translations?.all,
    });

    return {
      async pageReady() {
        const currentPageId = wixCodeApi.site.currentPage?.id;

        const pageReadyData = {
          currentPageId,
        };

        const initialData: InitialData = {
          ...hydratedSource,
          ...pageReadyData,
        };
        const { widgetData } = hydratedSource;

        const workerHandler = createWorkerHandler(
          controllerConfig,
          {
            debug,
          },
          flowAPI,
        );

        const dispatchEv = (action: any) =>
          workerHandler(restoreAction({ publishEvent, action }));

        if (pendingCurrentSiteUser) {
          initialData.__CURRENT_SITE_USER__ = pendingCurrentSiteUser;
        }

        const { handlers, getControllerState } = createHandlers(context);
        const storeProps = await createStore(
          getStoreBuilder,
          controllerConfig,
          initialData,
          setProps,
          debug,
          isSSR,
          isMobile,
          isModal,
          handlers,
          getControllerState,
        );

        if (isWixCodeSEOEnabled() && !isModal) {
          const appState = storeProps.appState;
          const videoEntities = getVideoEntities(appState);
          const videos = Object.values(videoEntities);
          const channel: Channel = getChannelById(
            appState,
            // this is string | null. When can it be null? Can we safely assume that by this point we always have currentChannelId?
            getCurrentChannelId(appState) as string,
          );
          const getSeoDataParams: GetSeoDataParams = widgetData.isSingleVideo
            ? {
                videos,
              }
            : {
                channel,
                videos,
              };

          const seoData = getSeoData(getSeoDataParams);
          wixCodeApi.seo.renderSEOTags({
            itemType: 'VIDEO',
            itemData: seoData,
          });
        }

        const componentProps = {
          ...storeProps,
          dispatchEv,
          consumeEvents,
          captureException: reportError,
          renderingEnv: wixCodeApi.window.rendering.env,
          experiments,
          debug,
          isSSR,
          instance,
          handlers,
          ...getMeshOverrides(),
        };

        setProps(componentProps);

        // we cant publish events before initial setProps (check on invalid video id from url)
        subscribeToEvents(setProps, platformAPIs.pubSub);

        return componentProps.appState;
      },
    };
  };

export default createController(
  createWorkerHandlerForOOI,
  getStoreBuilderForOOI,
  false,
);
